import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PostItem from './PostItem';
import { useTranslation } from 'react-i18next';

const SimilarArticles = ({ similarArticles }) => {
  const { t } = useTranslation();

  return (
    <div className="news-section pt-12 pb-8 bg-default-2">
      <Container>
        <Row className="justify-content-center">
          <div className="col-xl-6 col-lg-7 col-md-9 mt-9">
            <div className="section-title text-center mb-11 mb-lg-18">
              <h2 className="title gr-text-4 mb-6">{t('Similar Articles')}</h2>
            </div>
          </div>
        </Row>

        <Row
          className="justify-content-center"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          {similarArticles.map((item) => {
            const image =
              item.featuredImageRetina?.[0] || item.featuredImage?.[0];
            return (
              <Col md="4" lg="4" key={item.id} className="mb-5">
                <PostItem image={image} {...item} />
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default SimilarArticles;
