import React from 'react';
import BlogPage from '../components/Blog/BlogPage';
import { graphql } from 'gatsby';

const BlogEntryPage = (props) => {
  return <BlogPage propsData={props} />;
};

export default BlogEntryPage;

export const blogPageQuery = graphql`
  query blogPageQuery(
    $slug: String!
    $siteId: Int!
    $similarArticlesIds: [String]
  ) {
    similarBlogEntries: allCraftBlogBlogEntry(
      filter: { id: { in: $similarArticlesIds } }
    ) {
      nodes {
        id
        title
        slug
        author {
          name
          remoteId
        }
        postDate
        featuredImage {
          title
          ... on Craft_imagesEn_Asset {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 350
                  aspectRatio: 3.68
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
          ... on Craft_imagesRu_Asset {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 350
                  aspectRatio: 3.68
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
        }
        featuredImageRetina {
          title
          ... on Craft_imagesEn_Asset {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 350
                  aspectRatio: 3.68
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
          ... on Craft_imagesRu_Asset {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 350
                  aspectRatio: 3.68
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
        }
        category {
          title
          id
          slug
        }
      }
    }

    craftBlogBlogEntry(slug: { eq: $slug }, siteId: { eq: $siteId }) {
      title
      articleBody {
        ... on Craft_articleBody_newSection_BlockType {
          id
          typeHandle
          sectionHeading
          isLocked
        }
        ... on Craft_articleBody_heading_BlockType {
          id
          typeHandle
          isLocked
          heading
          headingId
          headingClass
        }
        ... on Craft_articleBody_text_BlockType {
          id
          position
          text
          typeHandle
          isLocked
        }
        ... on Craft_articleBody_pullQuote_BlockType {
          id
          isLocked
          pullQuote
          position
          typeHandle
        }
        ... on Craft_articleBody_image_BlockType {
          id
          caption
          image {
            url
            title
            ... on Craft_imagesEn_Asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, breakpoints: [330, 730])
                }
              }
            }
            ... on Craft_imagesRu_Asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, breakpoints: [330, 730])
                }
              }
            }
          }
          isLocked
          position
          typeHandle
        }
        ... on Craft_articleBody_quote_BlockType {
          id
          isLocked
          typeHandle
          quote
          position
        }
        ... on Craft_articleBody_button_BlockType {
          id
          isLocked
          title
          buttonTitle
          buttonType
          buttonUrl
          align
          typeHandle
        }
        ... on Craft_articleBody_textCenteredHighlighted_BlockType {
          id
          isLocked
          typeHandle
          text
        }
        ... on Craft_articleBody_supertable_BlockType {
          id
          table {
            ... on Craft_table_BlockType {
              column1
              column2
              column3
              id
            }
          }
          tablecolumncount
          typeHandle
          isLocked
        }
        ... on Craft_articleBody_domaininput_BlockType {
          id
          domaininputtitle
          typeHandle
        }
        ... on Craft_articleBody_stepitem_BlockType {
          id
          stepimage {
            id
            height
            width
            title
            ... on Craft_imagesEn_Asset {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, breakpoints: [330, 730])
                }
              }
            }
            ... on Craft_imagesRu_Asset {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, breakpoints: [330, 730])
                }
              }
            }
          }
          stepname
          stepnameasheading
          stepnametext
          stepurl
          typeHandle
        }
        ... on Craft_articleBody_stepitemhowto_BlockType {
          id
          stepitemhowtodirection
          stepitemhowtotip
          typeHandle
        }
        ... on Craft_articleBody_stepitemtool_BlockType {
          id
          typeHandle
          steptool
        }
        ... on Craft_articleBody_ldjsonquestionanswer_BlockType {
          ldJsonQuestion
          ldJsonAnswer
        }
      }
      author {
        name
        remoteId
        photo {
          url
          title
          ... on Craft_imagesEn_Asset {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 72)
              }
            }
          }
          ... on Craft_imagesRu_Asset {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 72)
              }
            }
          }
        }
      }
      category {
        title
        slug
      }
      postDate
      dateUpdated
      metaDescription
      metaKeywords
      metaNoindex
      metaTitle
      slug
      typeswitch
      blogHowtoTotaltime
      blogIsLocked
      featuredImage {
        title
        url
        height
        width
        ... on Craft_imagesEn_Asset {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 1110
                outputPixelDensities: [0.25, 0.5, 1, 1.7, 2]
                placeholder: BLURRED
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
        ... on Craft_imagesRu_Asset {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 1110
                outputPixelDensities: [0.25, 0.5, 1, 1.7, 2]
                placeholder: BLURRED
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
      }
      featuredImageRetina {
        title
        url
        height
        width
        ... on Craft_imagesEn_Asset {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 1110
                outputPixelDensities: [0.25, 0.5, 1, 1.7, 2]
                placeholder: BLURRED
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
        ... on Craft_imagesRu_Asset {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 1110
                outputPixelDensities: [0.25, 0.5, 1, 1.7, 2]
                placeholder: BLURRED
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
      }
      featuredImageSocial {
        title
        url
        height
        width
        ... on Craft_imagesEn_Asset {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1110
                placeholder: BLURRED
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
        ... on Craft_imagesRu_Asset {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1110
                placeholder: BLURRED
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
      }
      shortDescription
      shortDescInArticleBody
      subheading
      heading
    }
  }
`;
