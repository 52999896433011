import React from 'react';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import { Row, Col, Container } from 'react-bootstrap';
import NotFound from '../NotFound';
import CategoryWidget from './CategoryWidget';
import MetaTags from '../Seo/MetaTags';
import SimilarArticles from './SimilarArticles';
import { Helmet } from 'react-helmet-async';
import ArticleBody from './ArticleBody';
import { prepareJsonHowTo } from '../../services/JsonScripts';
import Link, { getLocalizedPath } from '../../i18n/Link';

import useDateFormat from '../../hooks/useDateFormat';
import useSiteMetadata from '../../hooks/useSiteMetadata';
import { usePageContext } from '../../i18n/PageContext';
import { useTranslation } from 'react-i18next';
import StructuredBreadcrumbs from '../Seo/StructuredBreadcrumbs';
import { defaultLanguage } from '../../../constants';
import { stripHtmlTags } from '../../../utils';

const BlogPage = ({
  propsData: {
    data: { craftBlogBlogEntry, similarBlogEntries },
    pageContext,
  },
}) => {
  const format = useDateFormat();
  const { t } = useTranslation('Blog');
  const { url } = useSiteMetadata();
  const { getPageUrl, i18n } = usePageContext();
  const { language } = i18n;

  const pageUrl = getPageUrl();
  const authorPath = getLocalizedPath(
    language,
    `/author/${craftBlogBlogEntry.author.remoteId}/`
  );

  if (!craftBlogBlogEntry) {
    return <NotFound />;
  }

  const { categories, previous, next } = pageContext;
  const featuredImage =
    craftBlogBlogEntry.featuredImageRetina[0] ||
    craftBlogBlogEntry.featuredImageSocial[0] ||
    craftBlogBlogEntry.featuredImage[0];
  const gatsbyImage = featuredImage && getImage(featuredImage.localFile);
  const authorImage =
    craftBlogBlogEntry.author.photo &&
    getImage(craftBlogBlogEntry.author.photo.localFile);
  const langPrefix = language === defaultLanguage ? '/' : `/${language}/`;

  const logoUrl = `${url}/blog-posting-logo.png`;
  const postDate = format(new Date(craftBlogBlogEntry.postDate), 'yyyy-dd-MM');
  const dateUpdated = format(
    new Date(craftBlogBlogEntry.dateUpdated),
    'yyyy-dd-MM'
  );

  const jsonHowTo = prepareJsonHowTo(craftBlogBlogEntry, langPrefix, url);

  const blogPath = getLocalizedPath(language, '/blog/');
  const breadcrumbs = [
    { name: t('index.seo.title'), url: `${url}${blogPath}` },
  ];

  if (craftBlogBlogEntry.category.length) {
    const categoryPath = getLocalizedPath(
      language,
      `/${craftBlogBlogEntry.category[0].slug}/`
    );

    breadcrumbs.push({
      name: craftBlogBlogEntry.category[0].title,
      url: `${url}${categoryPath}`,
    });
  }

  breadcrumbs.push({ name: craftBlogBlogEntry.title, url: pageUrl });

  return (
    <>
      <MetaTags
        title={
          craftBlogBlogEntry.metaTitle
            ? craftBlogBlogEntry.metaTitle
            : craftBlogBlogEntry.title
        }
        description={
          craftBlogBlogEntry.metaDescription ||
          stripHtmlTags(craftBlogBlogEntry.shortDescription)
        }
        keywords={craftBlogBlogEntry.metaKeywords}
        image={gatsbyImage ? gatsbyImage.images.fallback.src : ''}
        pageTitle={craftBlogBlogEntry.title}
      />

      <StructuredBreadcrumbs items={breadcrumbs} />

      <Helmet>
        {previous && (
          <link rel="prev" href={`${langPrefix}blog/${previous.slug}`} />
        )}
        {next && <link rel="next" href={`${langPrefix}blog/${next.slug}`} />}

        {craftBlogBlogEntry.typeswitch === 'howTo' && (
          <script type="application/ld+json">{`${JSON.stringify(
            jsonHowTo
          )}`}</script>
        )}
      </Helmet>

      <div itemScope={true} itemType="https://schema.org/BlogPosting">
        <meta itemProp="datePublished" content={postDate} />
        <meta itemProp="dateModified" content={dateUpdated} />
        <meta
          itemProp="description"
          content={craftBlogBlogEntry.metaDescription}
        />

        <meta
          itemScope
          itemProp="mainEntityOfPage"
          itemType="https://schema.org/WebPage"
          itemID={pageUrl}
        />

        <div
          itemProp="author"
          itemScope={true}
          itemType="https://schema.org/Person"
        >
          <meta itemProp="name" content={craftBlogBlogEntry.author.name} />
          <link itemProp="url" href={`${url}${authorPath}`} />
        </div>

        <div
          itemProp="publisher"
          itemScope={true}
          itemType="https://schema.org/Organization"
        >
          <meta itemProp="name" content="SpySERP" />
          <meta
            itemProp="address"
            content="27 Old Gloucester Street London WC1N 3AX United Kingdom"
          />
          <link itemProp="url" href="https://spyserp.com/" />
          <div
            itemProp="logo"
            itemScope={true}
            itemType="https://schema.org/ImageObject"
          >
            <meta itemProp="image" content={logoUrl} />
            <meta itemProp="width" content={550} />
            <meta itemProp="height" content={60} />
          </div>
        </div>

        <Container>
          {gatsbyImage && (
            <Row className="pt-15 pt-lg-20">
              <Col>
                <GatsbyImage
                  alt={craftBlogBlogEntry.title}
                  image={gatsbyImage}
                  loading="eager"
                  itemProp="image"
                />
              </Col>
            </Row>
          )}

          <CategoryWidget
            categories={categories}
            usedCategories={craftBlogBlogEntry.category}
            disableSearch
            showOnlyUsedCategories
          />

          <Row className="justify-content-center">
            <Col lg="9" xl="8">
              <div className="text-center">
                <h1
                  className="title gr-text-3 mb-5 mb-lg-5"
                  itemProp="headline"
                >
                  {craftBlogBlogEntry.title}
                </h1>

                <div
                  className="review-widget media align-items-center px-lg-7
                  flex-column flex-sm-row justify-content-center"
                >
                  <div className="widget-image mr-2 mr-5 mb-9 mb-md-0">
                    {authorImage && (
                      <GatsbyImage
                        className="circle-lg"
                        imgClassName="circle-lg"
                        alt={craftBlogBlogEntry.author.photo.title}
                        image={authorImage}
                        loading="eager"
                      />
                    )}

                    {!authorImage && (
                      <StaticImage
                        imgClassName="circle-lg"
                        src="../../assets/image/user.svg"
                        alt="Author"
                        width={72}
                        loading="eager"
                      />
                    )}
                  </div>

                  <div className="widget-text">
                    <div className="reviewer-block d-flex flex-wrap">
                      <h2 className="name gr-text-9 mr-2 mb-md-0">
                        <Link
                          to={`/author/${craftBlogBlogEntry.author.remoteId}/`}
                        >
                          {craftBlogBlogEntry.author.name}
                        </Link>
                        &nbsp; | &nbsp;
                      </h2>

                      <span className="excerpt gr-text-9 mb-0 gr-text-color-opacity">
                        {format(
                          new Date(craftBlogBlogEntry.postDate),
                          'dd MMM, yyyy'
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                {craftBlogBlogEntry.shortDescInArticleBody && (
                  <div
                    className="gr-text-8 mb-0"
                    dangerouslySetInnerHTML={{
                      __html: craftBlogBlogEntry.shortDescription,
                    }}
                  />
                )}

                {craftBlogBlogEntry.heading && (
                  <h2 className="title gr-text-7 mb-5 mb-lg-5">
                    {craftBlogBlogEntry.heading}
                  </h2>
                )}

                {craftBlogBlogEntry.subheading && (
                  <h3 className="gr-text-9 gr-text-color-opacity mb-5 mb-lg-5">
                    {craftBlogBlogEntry.subheading}
                  </h3>
                )}
              </div>
            </Col>
          </Row>
        </Container>

        <div
          className="about-content pt-lg-10 pt-5 pb-13 pb-lg-25"
          itemProp="articleBody"
        >
          <ArticleBody
            items={craftBlogBlogEntry.articleBody}
            pageUrl={pageUrl}
          />
        </div>
      </div>

      {similarBlogEntries && (
        <SimilarArticles similarArticles={similarBlogEntries.nodes} />
      )}
    </>
  );
};
export default BlogPage;
