import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { getLocalizedPath } from '../../i18n/Link';
import useSiteMetadata from '../../hooks/useSiteMetadata';

function StructuredBreadcrumbs(props) {
  const { items = [] } = props;
  const { url } = useSiteMetadata();
  const { i18n } = useTranslation();
  const { language } = i18n;

  const data = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'SpySERP',
        item: `${url}${getLocalizedPath(language, '/')}`,
      },
    ],
  };

  items.forEach((item, index) => {
    const { name, url } = item;
    if (name && url) {
      data.itemListElement.push({
        '@type': 'ListItem',
        position: index + 2,
        name,
        item: url,
      });
    }
  });

  return (
    <Helmet>
      <script type="application/ld+json">{`${JSON.stringify(data)}`}</script>
    </Helmet>
  );
}

StructuredBreadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default StructuredBreadcrumbs;
