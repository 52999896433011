import { formatISODuration } from 'date-fns';

export const prepareJsonHowTo = (entry, langPrefix, siteUrl) => {
  const blogHowtoTotalTime = entry.blogHowtoTotaltime
    ? entry.blogHowtoTotaltime
    : 10;
  const totalTime = formatISODuration({ minutes: blogHowtoTotalTime });
  const featuredImage = entry.featuredImage ? entry.featuredImage[0] : false;
  const output = {
    '@context': 'https://schema.org',
    '@type': 'HowTo',
    name: entry.title,
    description: entry.shortDescription
      ? entry.shortDescription.replace(/<\/?[^>]+>/gi, '')
      : '',
    supply: {
      '@type': 'HowToSupply',
      name: 'None',
    },
    totalTime,
  };

  if (featuredImage) {
    output.image = {
      '@type': 'ImageObject',
      url: `${siteUrl}${featuredImage.localFile.publicURL}`,
      height: featuredImage.height,
      width: featuredImage.width,
    };
  }

  const tmpData = {
    tools: [],
    steps: [],
  };
  let currStep = {};
  const typeKey = '@type';

  entry.articleBody.forEach(
    ({
      typeHandle,
      stepimage = false,
      stepname = false,
      stepnametext = false,
      stepurl = false,
      stepitemhowtodirection = false,
      stepitemhowtotip = false,
      steptool = false,
    }) => {
      if (typeHandle === 'stepitemtool') {
        tmpData.tools.push({
          '@type': 'HowToTool',
          name: steptool ? steptool.replace(/<\/?[^>]+>/gi, '') : '',
        });
      } else if (typeHandle === 'stepitem') {
        if (Object.keys(currStep).length && currStep.name) {
          tmpData.steps.push(currStep);
          currStep = {};
        }

        currStep[typeKey] = 'HowToStep';
        currStep.name = stepname ? stepname.replace(/<\/?[^>]+>/gi, '') : '';
        currStep.text = stepnametext
          ? stepnametext.replace(/<\/?[^>]+>/gi, '')
          : '';
        currStep.url = `${siteUrl}${langPrefix}blog/${entry.slug}/#${stepurl}`;
        currStep.itemListElement = [];

        if (stepimage.length) {
          const currStepImage = stepimage[0];
          currStep.image = {
            '@type': 'ImageObject',
            url: `${siteUrl}${currStepImage.localFile.publicURL}`,
            height: currStepImage.height,
            width: currStepImage.width,
          };
        }
      } else if (typeHandle === 'stepitemhowto') {
        if (stepitemhowtodirection) {
          if (!currStep.itemListElement) {
            currStep.itemListElement = [];
          }

          currStep.itemListElement.push({
            '@type': 'HowToDirection',
            text: stepitemhowtodirection
              ? stepitemhowtodirection.replace(/<\/?[^>]+>/gi, '')
              : '',
          });
        }

        if (stepitemhowtotip) {
          if (!currStep.itemListElement) {
            currStep.itemListElement = [];
          }

          currStep.itemListElement.push({
            '@type': 'HowToTip',
            text: stepitemhowtotip
              ? stepitemhowtotip.replace(/<\/?[^>]+>/gi, '')
              : '',
          });
        }
      } else if (Object.keys(currStep).length) {
        tmpData.steps.push(currStep);
        currStep = {};
      }
    }
  );

  if (Object.keys(currStep).length) {
    tmpData.steps.push(currStep);
  }

  if (tmpData.tools.length) {
    output.tool = tmpData.tools;
  }

  if (tmpData.steps.length) {
    output.step = tmpData.steps;
  }

  return output;
};
